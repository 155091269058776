export enum ROUTES_ENUM {
    PROFILE,
    REWARDS,
    ROLES,
    SHOPS,
    SIGN_IN,
    USERS,
}
const routes = {
    [ROUTES_ENUM.PROFILE]: "profile",
    [ROUTES_ENUM.REWARDS]: "rewards",
    [ROUTES_ENUM.ROLES]: "roles",
    [ROUTES_ENUM.SHOPS]: "shops",
    [ROUTES_ENUM.SIGN_IN]: "sign-in",
    [ROUTES_ENUM.USERS]: "users",
};

export default routes;
