import {
  IonButton,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import React from 'react';
import { authService } from '../services';
import './Profile.css';

const Profile: React.FC = () => {
  const handleSignOut = async () => {
    await authService.signOut();
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Profile</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonButton onClick={handleSignOut}>Sign Out</IonButton>
      </IonContent>
    </IonPage>
  );
};

export default Profile;
