import firebase from 'firebase/app';
import { UserProfile, UserReward } from '../models/user';

const getUserProfiles = async (): Promise<UserProfile[]> => {
  const snapshot = await firebase.firestore().collection('UserProfiles').get();

  return snapshot.docs.map((docs) => ({
    barberShop: docs.get('barberShop'),
    celPhone: docs.get('celPhone'),
    createdAt: docs.get('createdAt'),
    email: docs.get('email'),
    firstName: docs.get('firstName'),
    id: docs.get('id'),
    lastName: docs.get('lastName'),
    userType: docs.get('userType'),
    zipCode: docs.get('zipCode'),
  }));
};

const getUserRewards = async (userId: string): Promise<UserReward[]> => {
  const snapshot = await firebase
    .firestore()
    .collection('Rewards')
    .where('recipient', '==', userId)
    .get();

  return snapshot.docs.map((docs) => ({
    amount: docs.get('amount'),
    createdAt: docs.get('createdAt'),
    creator: docs.get('creator'),
    id: docs.get('id'),
    recipient: docs.get('recipient'),
    status: docs.get('status'),
    type: docs.get('type'),
    updatedAt: docs.get('updatedAt'),
  }));
};

const addUserReward = async (
  recipient: string,
  creator: string,
  amount: number
): Promise<
  firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
> => {
  const now = new Date();
  const data: Omit<UserReward, 'id' | 'createdAt' | 'updatedAt'> & {
    createdAt: Date;
    updatedAt: Date;
  } = {
    creator,
    recipient,
    type: 7,
    amount,
    createdAt: now,
    updatedAt: now,
    status: 'Created',
  };

  return firebase.firestore().collection('Rewards').add(data);
};

const userService = {
  addUserReward,
  getUserProfiles,
  getUserRewards,
};

export default userService;
