import { InputChangeEventDetail } from '@ionic/core';
import {
  IonAlert,
  IonBadge,
  IonButton,
  IonContent,
  IonFooter,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonNote,
  IonPage,
  IonText,
  useIonToast,
} from '@ionic/react';
import moment from 'moment';
import React, { useContext } from 'react';
import UserContext from '../../contexts/UserContext';
import { UserProfile, UserReward } from '../../models/user';
import { userService } from '../../services';

interface Props {
  onDismiss: () => void;
  userProfile?: UserProfile;
}

const getBalance = (rewards: UserReward[]) =>
  rewards
    .filter(({ status }) => status === 'Created')
    .reduce((prev, { amount }) => prev + amount, 0);

const renderPendingRewards = (rewards: UserReward[]) => {
  if (!rewards) return null;

  return rewards
    .filter(({ status }) => status === 'Created')
    .map((reward) => {
      const { id, amount, type, createdAt } = reward;
      const formattedDate = moment(createdAt.toDate()).format('MM/DD/YYYY');

      return (
        <IonItem key={id}>
          <IonLabel className="ion-text-wrap">
            {`${formattedDate}`} (Type: {type})
          </IonLabel>
          <IonNote slot="end">
            <IonBadge color="primary" className="badge">
              ${amount}
            </IonBadge>
          </IonNote>
        </IonItem>
      );
    });
};

const UserRewardsModalContent: React.FC<Props> = ({
  onDismiss,
  userProfile,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);
  const [userRewards, setUserRewards] = React.useState<UserReward[]>([]);
  const [amountToAdd, setAmountToAdd] = React.useState(0);
  const [presentToast, dismissToast] = useIonToast();

  const { user: creator } = useContext(UserContext);

  const getUserRewards = React.useCallback(async () => {
    if (!userProfile) return;

    const rewards = await userService.getUserRewards(userProfile.id);
    setUserRewards(rewards);
    setIsLoading(false);
  }, [userProfile]);

  const addUserReward = React.useCallback(async () => {
    if (!userProfile || !creator) return;

    try {
      await userService.addUserReward(userProfile.id, creator.uid, amountToAdd);
      presentToast({
        message: 'Rewards added successfully',
        buttons: [{ text: 'hide', handler: () => dismissToast() }],
        duration: 5000,
      });
      onDismiss();
    } catch (err) {
      presentToast({
        message: 'Unable to add rewards',
        buttons: [{ text: 'hide', handler: () => dismissToast() }],
        duration: 5000,
      });
      console.error('Unable to add reward', {
        userProfile,
        amountToAdd,
        message: err.message,
        code: err.code,
      });
    }

    setIsLoading(false);
  }, [
    amountToAdd,
    creator,
    dismissToast,
    onDismiss,
    presentToast,
    userProfile,
  ]);

  React.useEffect(() => {
    setIsLoading(true);
    getUserRewards();
  }, [getUserRewards]);

  const handleSubmit = () => {
    setShowAlert(true);
  };

  const handleAmountToAddChange = (
    event: CustomEvent<InputChangeEventDetail>
  ) => {
    const { value } = event.detail;
    if (!value) return;

    setAmountToAdd(+value);
  };

  const handleKeyPress: React.KeyboardEventHandler<HTMLIonInputElement> = (
    event
  ) => {
    if (event.key === 'Enter') handleSubmit();
  };

  const handleAddRewardAlertCancel = () => {
    setShowAlert(false);
  };

  const handleAddRewardAlertConfirm = () => {
    setIsLoading(true);
    addUserReward();
  };

  if (!userProfile) return null;

  if (isLoading) {
    return (
      <IonPage>
        <IonLoading isOpen={true} />
      </IonPage>
    );
  }

  const { firstName, lastName, email, celPhone } = userProfile;
  return (
    <IonPage>
      <IonContent class="content">
        <IonText>
          <h1>{`${firstName} ${lastName}`}</h1>
        </IonText>
        <IonText color="secondary">
          <h2>Current Balance: ${getBalance(userRewards)}</h2>
        </IonText>
        <IonList>
          <IonItem>
            <IonLabel position="stacked">Amount to add</IonLabel>
            <IonInput
              value={amountToAdd}
              onIonChange={handleAmountToAddChange}
              onKeyPress={handleKeyPress}
              type="number"
            ></IonInput>
          </IonItem>
        </IonList>
        <IonButton expand="block" color="primary" onClick={handleSubmit}>
          Add Reward Value
        </IonButton>
        <IonText color="secondary">
          <h3> Pending Rewards</h3>
        </IonText>
        <IonList>{renderPendingRewards(userRewards)}</IonList>
        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          header="Confirm"
          message={`Are you sure you want to add $${amountToAdd} to the account owned by ${firstName} ${lastName} (Email: ${email}, Cell: ${celPhone})`}
          buttons={[
            {
              text: 'Cancel',
              role: 'cancel',
              cssClass: 'secondary',
              handler: handleAddRewardAlertCancel,
            },
            {
              text: 'Okay',
              handler: handleAddRewardAlertConfirm,
            },
          ]}
        />
      </IonContent>
      <IonFooter>
        <IonButton expand="block" onClick={() => onDismiss()}>
          Close
        </IonButton>
      </IonFooter>
    </IonPage>
  );
};

export default UserRewardsModalContent;
