import {
  IonApp,
  IonLabel,
  IonLoading,
  IonPage,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/typography.css';
import firebase from 'firebase/app';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import './App.css';
import UserContext from './contexts/UserContext';
import SignIn from './pages/SignIn';
import Profile from './pages/Profile';
import Roles from './pages/Roles'
import Shops from './pages/Shops';
import Users from './pages/Users';
import { ProtectedRoute, routes, ROUTES_ENUM } from './routes';
/* Theme variables */
import './theme/variables.css';

const renderTab = (route: ROUTES_ENUM, label: string) => (
  <IonTabButton tab={routes[route]} href={`/${routes[route]}`} key={label}>
    <IonLabel className="tab-label">{label}</IonLabel>
  </IonTabButton>
);


const App: React.FC = () => {
  const [user, setUser] = React.useState<firebase.User | null>(null);
  const [authInitialized, setAuthInitialized] = React.useState(false);
  const [claims, setClaims] = React.useState<Record<string, any>>({});

  React.useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      setUser(user);
      if (user) {
        user.getIdTokenResult()
          .then(idTokenResult => {
            setClaims(idTokenResult.claims);
          });
      }

      if (!authInitialized) {
        setAuthInitialized(true);
      }
    });
  }, [authInitialized, setUser]);

  if (!authInitialized) {
    return (
      <IonPage>
        <IonLoading isOpen={true} />
      </IonPage>
    );
  }

  return (
    <UserContext.Provider value={{ user, setUser, claims }}>
      <IonApp>
        <IonReactRouter>
          <IonTabs>
            <IonRouterOutlet>
              <Route exact path={`/${routes[ROUTES_ENUM.SIGN_IN]}`}>
                <SignIn />
              </Route>
              <ProtectedRoute
                isAuthenticated={!!user && claims.admin}
                exact
                path={`/${routes[ROUTES_ENUM.USERS]}`}
              >
                <Users />
              </ProtectedRoute>
              <ProtectedRoute
                isAuthenticated={!!user}
                exact
                path={`/${routes[ROUTES_ENUM.PROFILE]}`}
              >
                <Profile />
              </ProtectedRoute>
              <ProtectedRoute
                isAuthenticated={!!user && claims.admin}
                exact
                path={`/${routes[ROUTES_ENUM.ROLES]}`}
              >
                <Roles />
              </ProtectedRoute>
              <Route exact path="/">
                <Redirect to={`/${routes[ROUTES_ENUM.USERS]}`} />
              </Route>
            </IonRouterOutlet>
            <IonTabBar slot="top">
              {user
                ? [
                  renderTab(ROUTES_ENUM.USERS, 'Users'),     
                  renderTab(ROUTES_ENUM.PROFILE, 'Profile'),
                  renderTab(ROUTES_ENUM.ROLES, 'Roles'),
                ]
                : renderTab(ROUTES_ENUM.SIGN_IN, 'Sign In')}
            </IonTabBar>
          </IonTabs>
        </IonReactRouter>
      </IonApp>
    </UserContext.Provider>
  );
};

export default App;
