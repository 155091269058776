import firebase from 'firebase/app';
import localStorageService from './localStorageService';

const sendSignInEmailLink = async (email: string) => {
  const actionCodeSettings: firebase.auth.ActionCodeSettings = {
    // TODO: handle case where LOGIN_REDIRECT cannot be found
    url: process.env.REACT_APP_LOGIN_REDIRECT!,
    handleCodeInApp: true,
  };
  await firebase.auth().sendSignInLinkToEmail(email, actionCodeSettings);
  localStorageService.setSignInEmail(email);
};

const signInWithEmailLink = async (email: string) =>
  await firebase.auth().signInWithEmailLink(email, window.location.href);

const signOut = async () => firebase.auth().signOut();

const verifySignInLink = () => {
  if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
    let email = localStorageService.getSignInEmail();
    if (!email) {
      /**
            User opened the link on a different device.To prevent session fixation attacks, ask the user to provide the associated email again. If users cancels prompt, email will be null and link will be marked invalid.
             */
      email = window.prompt('Please provide your email for confirmation');
    }
    return email;
  }

  return null;
};

const authService = {
  sendSignInEmailLink,
  signInWithEmailLink,
  signOut,
  verifySignInLink,
};

export default authService;
