import {
  IonBadge,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonModal,
  IonNote,
  IonPage,
  IonSegment,
  IonSegmentButton,
  IonTitle,
  IonToolbar,
  useIonModal,
} from '@ionic/react';
import { partition, sortBy } from 'lodash';
import React from 'react';
import { UserRewardsModalContent } from '../components/user-rewards';
import { UserProfile } from '../models/user';
import { UserTypes } from '../models/enum/userType.enum';
import { userService } from '../services';
import './Users.css';


type Segment = 'hp' | 'client';

const segmentValues: Record<UserTypes, Segment> = {
  [UserTypes.HP]: 'hp',
  [UserTypes.CLIENT]: 'client',
};

const renderUsers = (
  userProfiles: UserProfile[],
  clickHandlerWithProfile: (profile: UserProfile) => () => void
) => {
  if (!userProfiles) return null;

  return userProfiles.map(profile => {
    const { id, firstName, lastName, email, userType } = profile;
    return (
      <IonItem key={id} onClick={clickHandlerWithProfile(profile)}>
        <IonNote slot="end">
          <IonBadge color="primary" className="badge">
            {userType === UserTypes.HP ? 'HP' : 'C'}
          </IonBadge>
        </IonNote>
        <IonLabel>{`${firstName} ${lastName} (${email})`}</IonLabel>
      </IonItem>
    );
  });
};

const partitionUsers = (profiles: UserProfile[]) => {
  const sortedUsers = sortBy(profiles, ({ firstName }) => firstName);
  const partitionedUsers = partition(sortedUsers, { userType: UserTypes.HP });
  return partitionedUsers;
};

const Users: React.FC = () => {
  const [userProfiles, setUserProfiles] = React.useState<UserProfile[][]>([]);
  const [loadingProfiles, setLoadingProfiles] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);

  const [selectedUserProfile, setSelectedUserProfile] = React.useState<
    UserProfile
  >();

  const [segment, setSegment] = React.useState<Segment>(
    segmentValues[UserTypes.HP]
  );

  const getUserProfiles = React.useCallback(async () => {
    const profiles = await userService.getUserProfiles();
    setUserProfiles(partitionUsers(profiles));
    setLoadingProfiles(false);
  }, []);

  React.useEffect(() => {
    setLoadingProfiles(true);
    getUserProfiles();
  }, [getUserProfiles]);

  const handleIonSegmentChange = (
    e: CustomEvent<{ value: string | undefined }>
  ) => {
    setSegment((e.detail.value as Segment) || UserTypes.HP);
  };

  const handleModalDismiss = () => {
    setShowModal(false);
  };

  const clickHandlerWithProfile = (profile: UserProfile) => () => {
    setSelectedUserProfile(profile);
    setShowModal(true);
  };

  if (loadingProfiles) {
    return (
      <IonPage>
        <IonLoading isOpen={true} />
      </IonPage>
    );
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Users</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Users</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonSegment onIonChange={handleIonSegmentChange} value={segment}>
          <IonSegmentButton value={segmentValues[UserTypes.HP]}>
            <IonLabel>Hair Professionals</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value={segmentValues[UserTypes.CLIENT]}>
            <IonLabel>Clients</IonLabel>
          </IonSegmentButton>
        </IonSegment>
        <IonList className="users-list">
          {segment === segmentValues[UserTypes.HP]
            ? renderUsers(userProfiles[0], clickHandlerWithProfile)
            : renderUsers(userProfiles[1], clickHandlerWithProfile)}
        </IonList>
        <IonModal isOpen={showModal} cssClass="">
          <UserRewardsModalContent
            userProfile={selectedUserProfile}
            onDismiss={handleModalDismiss}
          />
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default Users;
