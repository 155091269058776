import { RouteProps, Route, Redirect } from 'react-router';
import routes, { ROUTES_ENUM } from './routes';

interface ProtectedRouteProps extends RouteProps {
  isAuthenticated: boolean;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = props => {
  const { children, isAuthenticated, ...routeProps } = props;
  return (
    <Route {...routeProps}>
      {isAuthenticated ? (
        <>{children}</>
      ) : (
        <Redirect to={`/${routes[ROUTES_ENUM.SIGN_IN]}`} />
      )}
    </Route>
  );
};

export default ProtectedRoute;
