import { InputChangeEventDetail } from '@ionic/core';
import {
  IonButton,
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonPage,
  IonText,
  useIonToast,
} from '@ionic/react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import UserContext from '../contexts/UserContext';
import { routes, ROUTES_ENUM } from '../routes';
import { authService } from '../services';
import './SignIn.css';
import firebase from 'firebase';
// import * as functions from "firebase-functions"




const Roles: React.FC = () => {
  const [email, setEmail] = React.useState('');
  const [showLoading, setShowLoading] = React.useState(false);
  const [presentToast, dismissToast] = useIonToast();

  const handleSubmit = async () => {
    try {
      const functions = firebase.functions();
      const addAdmin = functions.httpsCallable("addAdmin");
      await addAdmin({ email: email }).then(result => {
        console.log(result.data['message']);
        presentToast({
          message: result.data['message'],
          buttons: [{ text: 'hide', handler: () => dismissToast() }],
        });
      })
    } catch (err) {
      presentToast({
        message: 'Unable to send request to add admin role',
        buttons: [{ text: 'hide', handler: () => dismissToast() }],
      });
      console.error('Unable to send request to add admin role', {
        email,
        message: err.message,
        code: err.code,
      });
      return err;
    }

  };

  const handleEmailChange = (event: CustomEvent<InputChangeEventDetail>) => {
    const { value } = event.detail;
    if (!value) return;
    setEmail(value);
  };

  const handleKeyPress: React.KeyboardEventHandler<HTMLIonInputElement> = (
    event
  ) => {
    if (event.key === 'Enter') handleSubmit();
  };

  return (
    <IonPage>
      <IonContent fullscreen className="content">
        <IonText color="secondary">
          <h1>Add Admin Role</h1>
        </IonText>
        <IonList>
          <IonItem>
            <IonLabel position="stacked">Email</IonLabel>
            <IonInput
              value={email}
              onIonChange={handleEmailChange}
              onKeyPress={handleKeyPress}
            ></IonInput>
          </IonItem>
        </IonList>
        <IonButton expand="block" color="primary" onClick={handleSubmit}>
          Add Admin Role
        </IonButton>
        <IonLoading
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={'Please wait...'}
        />
      </IonContent>
    </IonPage>
  );
};

export default Roles;
