import React from "react";
import firebase from "firebase/app";
import { noop } from "lodash";

type User = firebase.User | null;

export default React.createContext<{
    setUser: (user: User) => void;
    user: User;
    claims: {};
}>({ user: null, setUser: noop, claims:{} });
