import { InputChangeEventDetail } from '@ionic/core';
import {
  IonButton,
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonPage,
  IonText,
  useIonToast,
} from '@ionic/react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import UserContext from '../contexts/UserContext';
import { routes, ROUTES_ENUM } from '../routes';
import { authService } from '../services';
import './SignIn.css';

const SignIn: React.FC = () => {
  const { setUser } = React.useContext(UserContext);
  const [email, setEmail] = React.useState('');
  const [showLoading, setShowLoading] = React.useState(false);
  const [presentToast, dismissToast] = useIonToast();
  const history = useHistory();

  const handleSignInWithEmailLink = React.useCallback(
    async (signInLinkEmail: string) => {
      const cred = await authService.signInWithEmailLink(signInLinkEmail);

      setUser(cred.user);
      setShowLoading(false);
      history.push(routes[ROUTES_ENUM.USERS]);
      return;
    },
    [history, setUser]
  );

  React.useEffect(() => {
    const fn = async () => {
      setShowLoading(true);
      try {
        const signInLinkEmail = await authService.verifySignInLink();
        if (signInLinkEmail) {
          await handleSignInWithEmailLink(signInLinkEmail);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setShowLoading(false);
      }
    };

    fn();
    return () => setShowLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async () => {
    try {
      await authService.sendSignInEmailLink(email);
      presentToast({
        message: 'Email sent successfully. Check inbox',
        buttons: [{ text: 'hide', handler: () => dismissToast() }],
        duration: 5000,
      });
    } catch (err) {
      presentToast({
        message: 'Unable to send sign in with provided email',
        buttons: [{ text: 'hide', handler: () => dismissToast() }],
        duration: 5000,
      });
      console.error('Unable to send sign in email link', {
        email,
        message: err.message,
        code: err.code,
      });
      return err;
    }
  };

  const handleEmailChange = (event: CustomEvent<InputChangeEventDetail>) => {
    const { value } = event.detail;
    if (!value) return;

    setEmail(value);
  };

  const handleKeyPress: React.KeyboardEventHandler<HTMLIonInputElement> = event => {
    if (event.key === 'Enter') handleSubmit();
  };

  return (
    <IonPage>
      <IonContent fullscreen className="content">
        <IonText color="secondary">
          <h1>Sign In</h1>
        </IonText>
        <IonList>
          <IonItem>
            <IonLabel position="stacked">Email</IonLabel>
            <IonInput
              value={email}
              onIonChange={handleEmailChange}
              onKeyPress={handleKeyPress}
            ></IonInput>
          </IonItem>
        </IonList>
        <IonButton expand="block" color="primary" onClick={handleSubmit}>
          Send Sign In Email
        </IonButton>
        <IonLoading
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={'Please wait...'}
        />
      </IonContent>
    </IonPage>
  );
};

export default SignIn;
