const AUTH_SIGN_IN_EMAIL = "authSignInEmail";

const setSignInEmail = (email: string) => {
    localStorage.setItem(AUTH_SIGN_IN_EMAIL, email);
};
const clearSignInEmail = () => {
    localStorage.removeItem(AUTH_SIGN_IN_EMAIL);
};
const getSignInEmail = () => localStorage.getItem(AUTH_SIGN_IN_EMAIL);

const localStorageService = {
    clearSignInEmail,
    getSignInEmail,
    setSignInEmail,
};
export default localStorageService;
